import React, { useState, useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import Theme from "../../theme.module.css";
import Style from "./CheckoutForm.module.css";
import gs_dataLayer from '../../gs_modules/dataLayer';
import { updateRates } from "../shippingRates/shippingRatesSlice";
import { PaymentElement, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from "react-redux";
import ordersSlice, { createOrder } from "../order/ordersSlice";
import { resolveStripeEvent, prepareShippingRates } from "./stripeSlice";
import { updateCart, updateCartWithShippingRate } from "../cart/cartSlice";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ShippingRates from "../shippingRates/ShippingRates";
import OrderSubTotals from "../checkout/OrderSubTotals";
import OrderTotal from "../checkout/OrderTotal";
import RightSlideIn from "../../layouts/sidebar/RightSlideIn";
import GoBack from "../../features/goBack/GoBack";
import './Checkout.css';


export default function CheckoutForm({clientSecret, getClientSecret, options, setOptions, step, setStep}) {

    const [fields, setFields] = useState({});
    const [stripeAwaitingShippingRates, setstripeAwaitingShippingRates] = useState(false);
    const [stripeAwaitingShippingRateSelected, setstripeAwaitingShippingRateSelected] = useState(false);
    const [stripeCanResolve, setstripeCanResolve] = useState(false);
    const [differentBillingAddress, setDifferentBillingAddress] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(true);
    const [marketingAgreed, setMarketingAgreed] = useState(false);
    const [createPassword, setCreatePassword] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [indempotencyKey, setIndempotencyKey] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentID, setPaymentID] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [orderID, setOrderID] = useState(null);
    const [expressCheckoutReady, setExpressCheckoutReady] = useState(false);
    const { currentLanguage, localeCode } = useSelector(state => state.internationalisation);
    const { shippingRates, availCountries, taxRate } = useSelector(state => state.shippingRates);
    const { currentCurrency } = useSelector(state => state.internationalisation);
    const cartData = useSelector(state => state.cart);
    const {t, i18n} = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    const dispatch = useDispatch();
    const elements = useElements();
    const stripe = useStripe();
    
    const requiredFields1 = [
        'shipping_first_name',
        'shipping_last_name',
        'shipping_address_1',
        'shipping_city',
        'shipping_postcode',
        'billing_email',
        'billing_phone'
    ];

    const requiredFields2 = [
        'billing_first_name',
        'billing_last_name',
        'billing_address_1',
        'billing_city',
        'billing_postcode'
    ];

    // initialise indempotencyKey
    useEffect(() => {
        // create random key
        let key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        setIndempotencyKey(key);
    }, [])

    // set initial shipping and billing country
    useEffect(() => {

        if (!availCountries || availCountries.length == 0 || fields.shipping_country || fields.billing_country) {
            return;
        }

        let countryCode = availCountries[0].code.toLowerCase();

        setFields({
            ...fields,
            shipping_country: countryCode,
            billing_country: countryCode
        });
        
    }, [availCountries]);

    
    // handle field change
    const handleChange = (event) => {

        const name = event.target.name;

        // checkboxes
        if (name == 'differentBillingAddress' || name == 'agree_terms') {
            let value = event.target.checked;
            console.log(name, value);
            setFields({...fields, [name]: value});
            setDifferentBillingAddress(value);

            if (!event.target.checked) {
                setBillingAddressToShipping();
            }
        }

        // other inputs
        else {
            let value = event.target.value;
            setFields({...fields, [name]: value});
        }

        // if shipping country changed update shipping 
        if (name == 'shipping_country') {
            console.log('shipping country changed');
            console.log(event.target.value);

            // same address for billing & shipping
            if (!differentBillingAddress) {
                dispatch(updateRates({
                    shippingAddress: {
                        country: event.target.value,
                    },
                    billingAddress: {
                        country: event.target.value,
                    }
                }));
            }
            else {
                dispatch(updateRates({
                    shippingAddress: {
                        country: event.target.value,
                    }
                }));
            }
        }

        // if billing country changed update taxes
        else if (name == 'billing_country') {
            console.log('billing country changed');
            console.log(event.target.value);

            dispatch(updateRates({
                billingAddress: {
                    country: event.target.value,
                }
            }));
        }
    }

    // toggle create password
    const toggleCreatePassword = () => {
        if (createPassword) {
            setCreatePassword(false);
        }
        else {
            setCreatePassword(true);
        }
    }

    // handle checkout submit
    const handleSubmit = async (event) => {
        event.preventDefault();

        //event.target.querySelector('input[type="submit"]').disabled = true;

        if (processing) {
            console.log('payment in process - would usually bail here');
            return;
        }

        setProcessing(true);
        setPaymentError(null);
        console.log(fields);

        if (!validateStep3()) {
            setProcessing(false);
            return;
        }

        console.log('checkout validated');

        handleStripePayment();
    }

    // re enable pay button
    // useEffect(() => {
    //     if (document.querySelector('.checkoutFormOuter input[type="submit"]') && !processing) {
    //         document.querySelector('.checkoutFormOuter input[type="submit"]').disabled = false;
    //     }
    // }, [processing]);

    // handle step forward
    const handleStepForward = () => {

        if (step == 2) {
            if (!validateStep2()) {
                return;
            }
        }

        if (step == 1 || step == 2) {
            setStep(step + 1);
        }
    }

    // handle step backward
    const handleStepBackward = () => {
        if (step == 3 || step == 2) {
            setStep(step - 1);
            setValidationErrors([]);
            setFormValid(true);
        }
    }

    // validate form
    const validateStep2 = () => {

        let valid = true;
        setValidationErrors([]);
        setFormValid(true);

        if (!termsAgreed) {
            valid = false;
            setFormValid(false);
            let errorString = t('cart.agree-terms-required');
            setValidationErrors((validationErrors) => [...validationErrors, errorString]);
        }

        requiredFields1.forEach((key) => {
            console.log(key, fields[key]);
            if (fields[key] === undefined || fields[key] == '' || fields[key] == false) {
                valid = false;
                setFormValid(false);
                let errorString = key + ' ' + t('cart.is-required-field');
                setValidationErrors((validationErrors) => [...validationErrors, errorString]);
            }
        })

        if (fields.account_password) {
            if (fields.account_password !== fields.account_password_2) {
                valid = false;
                setFormValid(false);
                let errorString = t('cart.password-mismatch');
                setValidationErrors((validationErrors) => [...validationErrors, errorString]);
            }
        }

        // check if shipping country is set
        if (!fields.shipping_country) {
            valid = false;
            setFormValid(false);
            let errorString = t('cart.shipping-country-required');
            setValidationErrors((validationErrors) => [...validationErrors, errorString]);
        }

        // update billing fields state from shipping
        if (!differentBillingAddress) {
            setBillingAddressToShipping();
        }

        console.log(fields);

        dispatch(updateRates({
            shippingAddress: {
                country: fields.shipping_country,
                postcode: fields.shipping_postcode
            }
        }));

        return valid;
    }

    // validate step 3
    const validateStep3 = () => {

        let valid = true;
        setValidationErrors([]);
        setFormValid(true);

        requiredFields2.forEach((key) => {
            if (fields[key] === undefined || fields[key] == '') {
                valid = false;
                setFormValid(false);
                let errorString = key + ' is a required field';
                setValidationErrors((validationErrors) => [...validationErrors, errorString]);
            }
        })

        return valid;
    }

    // copy the shipping address to the billing address fields
    const setBillingAddressToShipping = () => {
        setFields({...fields, 
            billing_first_name: fields.shipping_first_name,
            billing_last_name: fields.shipping_last_name,
            billing_address_1: fields.shipping_address_1,
            billing_address_2: fields.shipping_address_2,
            billing_city: fields.shipping_city,
            billing_postcode: fields.shipping_postcode,
            billing_country: fields.shipping_country
        });
    }

    // configure stripe express checkout
    const configureExpressCheckout = (elements, options) => {

        if (!stripe || !elements) {
            return;
        }
        
        const expressCheckoutElement = elements.create('expressCheckout', {
            buttonHeight: 52,
            layout: {
                maxColumns: 1,
                overflow: 'never'
            }
        });

        // check if #express-payments element exists
        if (!document.querySelector('#express-payments')) {
            return;
        }

        expressCheckoutElement.mount('#express-payments');
        console.log(elements);

        expressCheckoutElement.on('ready', () => {
            setExpressCheckoutReady(true);
        });

        expressCheckoutElement.on('click', (event) => {
            console.log('express checkout clicked');
            console.log(event);
            console.log(shippingRates);

            const {elementType, resolve, expressPaymentType} = event;

            console.log('avail shipping countries', availCountries);

            let allowedShippingCountries = [];

            availCountries.forEach((availCountry) => {
                allowedShippingCountries.push(availCountry.code);
            });
    
            const options = {
                emailRequired: true,
                phoneNumberRequired: true,
                billingAddressRequired: true,
                shippingAddressRequired: true,
                allowedShippingCountries: allowedShippingCountries,
                shippingRates: prepareShippingRates(shippingRates)
            };
    
            // Show the payment interface
            resolve(options);
        });

        // express checkout billing address change
        // expressCheckoutElement.on('billingaddresschange', async (event) => {

        //     const {resolve, address} = event;

        //     console.log('billing address change');
        //     console.log(address);

        //     // await updatecartEventHandled(true);

        //     dispatch(updateRates({
        //         billingAddress: {
        //             country: address.country,
        //         }
        //     }));

        //     setstripeAwaitingShippingRates(event);
        // });
    
        // express checkout shipping address change
        expressCheckoutElement.on('shippingaddresschange', async (event) => {

            const {resolve, address} = event;

            console.log('shipping address change');
            console.log(address);

            // update shipping rates
            dispatch(updateRates({
                shippingAddress: {
                    country: address.country,
                    postcode: address.postal_code
                }
            }))
            .then(() => {
                // awaits the new shipping rates and updates the cart with the new shipping rate
                dispatch(updateCartWithShippingRate())
                .then(() => {
                    // resolve the stripe event now cart is updated
                    dispatch(resolveStripeEvent({
                        stripeEvent: event,
                        elements: elements
                    }));
                })
            })

        });

        // express checkout shipping rate change
        expressCheckoutElement.on('shippingratechange', async (event) => {

            const {resolve, shippingRate} = event;

            console.log('shipping option change');
            console.log(shippingRate);

            let newCartData = {
                ...cartData,
                selectedShippingRate: shippingRate.id
            }
    
            dispatch(updateCart({cartData: newCartData}))
            .then(() => {
                // resolve the stripe event now cart is updated
                dispatch(resolveStripeEvent({
                    stripeEvent: event,
                    elements: elements
                }));
            })
            .catch((error) => {
                console.log(error);
            });

        });

        expressCheckoutElement.on('confirm', async (event) => {

            console.log(event);

            // get Shipping name from name field
            let shippingName = event.shippingAddress.name.split(' ');
            let shippingFirstName = shippingName[0];
            let shippingLastName = shippingName[shippingName.length-1];

            // get billing name from name field
            let billingName = event.billingDetails.name.split(' ');
            let billingFirstName = billingName[0];
            let billingLastName = billingName[billingName.length-1];

            // set fields from stripe event
            let expressOrderFields = {
                shipping_first_name: shippingFirstName,
                shipping_last_name: shippingLastName,
                shipping_address_1: event.shippingAddress.address.line1,
                shipping_address_2: event.shippingAddress.address.line2,
                shipping_city: event.shippingAddress.address.city,
                shipping_postcode: event.shippingAddress.address.postal_code,
                shipping_country: event.shippingAddress.address.country,
                billing_first_name: billingFirstName,
                billing_last_name: billingLastName,
                billing_address_1: event.billingDetails.address.line1,
                billing_city: event.billingDetails.address.city,
                billing_postcode: event.billingDetails.address.postal_code,
                billing_country: event.billingDetails.address.country,
                billing_email: event.billingDetails.email,
                billing_phone: event.billingDetails.phone,
                shipping_method_title: event.shippingRate.displayName,
                shipping_method_id: event.shippingRate.id,
                shipping_total: (event.shippingRate.amount / 100).toFixed(2)
            };
        
            console.log(expressOrderFields);

            handleStripePayment(expressOrderFields);
        });
    }


    useEffect(() => {
        configureExpressCheckout(elements);
        setExpressCheckoutReady(true);
    }, [elements]);


    // Handle Stripe Payment
    const handleStripePayment = async (newFields = null) => {

        if (!stripe) {
            return;
        }

        const {error: submitError} = await elements.submit();

        if (submitError) {
            console.log(submitError)
            setPaymentError('Payment error: ' + submitError.message);
            setProcessing(false);
            return;
        }

        // get total
        let total = cartData.total;

        // Fetch client secret
        let secret = await getClientSecret(total, currentCurrency, indempotencyKey);

        // it's not getting here currently due to indempontent requests returning the same client secret
        if (!secret) {
            if (clientSecret) {
                // use secret from state (payment intent is already created)
                secret = clientSecret;

                // if orderID and client secret are set, user has pressed btn again, try confirming & completing the order again
                if (orderID) {
                    console.log('order is already set here, going to retry completing the order');
                    completeOrderProcess();
                    return;
                } else {
                    console.log('creating order - client secret is already stored in state, but orderID is not');
                }

            } else {
                // Bail - client secret is not yet created, but the server failed to create one (get's here after multiple button presses during payment process)
                console.log('bailing - client secret is not yet stored in state and server failed to create one (gets here after multiple button presses during payment process');
                setProcessing(false);
                return;
            }
        }

        // FB tracking event ID
        let eventID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        // add client secret & fb eventID to orderData 
        if (!newFields) {
            newFields = {
                ...fields,
                clientSecret: secret,
                currency: currentCurrency,
                cartData: cartData,
                taxRate: taxRate,
                eventID: eventID,
                marketingAgreed: marketingAgreed
            }
        } else {
            newFields = {
                ...newFields,
                clientSecret: secret,
                currency: currentCurrency,
                cartData: cartData,
                taxRate: taxRate,
                eventID: eventID,
                marketingAgreed: marketingAgreed
            }
        }

        console.log('cart data before sending:', cartData);

        // Create order on the server
        let orderResponse = await dispatch(createOrder({orderData: newFields}));
        setOrderID(orderResponse.payload.id);
        console.log('should be an order respone', orderResponse);

        // order process is completed when client secret and order id are set
        
    }


    // client secret is updated -> complete the order process
    useEffect(() => {
        completeOrderProcess();
    }, [clientSecret, orderID]);


    // Complete the order process
    const completeOrderProcess = () => {
        if (!stripe || !clientSecret || !orderID) {
            return;
        }

        // Confirm the PaymentIntent using the details collected by the Express Checkout Element
        const {error} = stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                return_url: window.location.origin + '/order-recieved/' + orderID
            }
        }).then(function(result) {
            if (result.error) {
                console.log('payment error', result.error);

                setPaymentError('Payment error: ' + result.error.message);
    
                // reset indempotency key
                let key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
                setIndempotencyKey(key);
    
                setOrderID(null);
                setProcessing(false);
            }
        });
    }


    const paymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true
        }
    }


    // data layer - begin checkout
    useEffect(() => {
        if (!cartData || !currentCurrency) {
            return;
        }

        gs_dataLayer.beginCheckout(cartData, currentCurrency);
    }, []);


    return (
        <div className="checkoutFormOuter">
            <div>
                <p>{t('cart.step')} {step} {t('cart.of')} 3</p>
            </div>

            <form onSubmit={handleSubmit}>
                {step &&
                    <>
                    <CSSTransition
                        in={step == 1}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className={`step step-1 ${step == 1 ? 'active' : ''}`}>
                            {expressCheckoutReady ?
                                <a className={Theme.btnDarkBig} onClick={handleStepForward}>{t('checkout.checkout')}</a>
                                :
                                <div className={Theme.loadingSpinner}></div>
                            }
                            <div id="express-payments"></div>
                            <GoBack text={t('checkout.go-back')}/>
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={step == 2}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                    >
                        <div className={`step step-2 ${step == 2 ? 'active' : ''}`}>
                            <div className="delivery">
                                <h3>{t('cart.delivery-details')}</h3>
                                {validationErrors && validationErrors.length > 0 && (
                                    <div className={Style.validationErrors}>
                                        {validationErrors.map((validationError) => (
                                            <p>{validationError}</p>
                                        ))}
                                    </div>
                                )}

                                <label>
                                    {t('login.email')}
                                    <input type="email" name="billing_email" placeholder={t('checkout.placeholder-email')} value={fields.billing_email} onChange={handleChange}/>
                                </label>

                                <a className={Theme.btnTextGrey} onClick={toggleCreatePassword}>Create a password and login next time</a>

                                {createPassword && 
                                    <label>
                                        {t('login.password')}
                                        <input type="password" name="account_password" placeholder={t('checkout.placeholder-password')} value={fields.account_password} onChange={handleChange}/>
                                        <input type="password" name="account_password_2" placeholder={t('checkout.placeholder-confirm-password')} value={fields.account_password_2} onChange={handleChange}/>
                                    </label>
                                }

                                <label>
                                    {t('cart.name')}
                                    <input type="text" name="shipping_first_name" placeholder={t('checkout.placeholder-first-name')} value={fields.shipping_first_name} onChange={handleChange} />
                                    <input type="text" name="shipping_last_name" placeholder={t('checkout.placeholder-last-name')} value={fields.shipping_last_name} onChange={handleChange}/>
                                </label>

                                <label>
                                    {t('cart.address')}
                                    <input type="text" name="shipping_address_1" placeholder={t('checkout.placeholder-address-1')} value={fields.shipping_address_1} onChange={handleChange}/>
                                    <input type="text" name="shipping_address_2" placeholder={t('checkout.placeholder-address-2')} value={fields.shipping_address_2} onChange={handleChange}/>
                                    <input type="text" name="shipping_city" placeholder={t('checkout.placeholder-town')} value={fields.shipping_city} onChange={handleChange}/>
                                    <input type="text" name="shipping_postcode" placeholder={t('checkout.placeholder-postcode')} value={fields.shipping_postcode} onChange={handleChange}/>

                                    {availCountries && fields.shipping_country &&
                                        <select value={fields.shipping_country} name="shipping_country" onChange={handleChange}>
                                            {availCountries.map((availCountry) => (
                                                <option key={availCountry.code} value={availCountry.code}>
                                                    {availCountry.name}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                </label>

                                <span className={Style.countryNotListed}>{t('checkout.country-not-listed')}</span>

                                <label>
                                    {t('cart.phone-number')}
                                    <input type="text" name="billing_phone" placeholder={t('checkout.placeholder-phone')} value={fields.billing_phone} onChange={handleChange}/>
                                </label>

                                <label className={Style.agreeTerms}>
                                    <input type="checkbox" name="agree_terms" onClick={() => setTermsAgreed(!termsAgreed)} checked={termsAgreed}/>
                                    {t('cart.agree-terms-1')}: <Link to="/returns">{t('footer.returns')}</Link>, <Link to="/delivery-information">{t('footer.delivery')}</Link> & <Link to="/privacy">{t('footer.privacy-policy')}</Link>
                                </label>

                                <label className={Style.agreeTerms}>
                                    <input type="checkbox" name="agree_marketing" onClick={() => setMarketingAgreed(!marketingAgreed)} checked={marketingAgreed}/>
                                    {t('cart.agree-marketing-comms')}
                                </label>

                                <div className={Style.controlsCont}>
                                    <a className={Theme.btnTextGrey} onClick={handleStepBackward}>Previous step</a>
                                    <a className={Theme.btnDarkBig} onClick={handleStepForward}>Proceed to payment</a>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>

                    <CSSTransition
                        in={step == 3}
                        timeout={500}
                        classNames="fade"
                        unmountOnExit
                    >
                        <>
                        {isMobile &&
                            <div className={`step step-3 ${step == 3 ? 'active' : ''}`}>
                                <h3>{t('cart.summary')}</h3>
                                <ShippingRates />
                                <OrderSubTotals />
                                { /*<PromoCode /> */}
                                <div className="bottomCont">
                                    <OrderTotal />
                                </div>
                            </div>
                        }

                            <div className={isMobile && 'mobileBottomCont mobileCheckoutBottomCont'}>
                                <div className="fade-2">

                                    {isMobile &&
                                        <img src={require('../../assets/img/mobile_handle.svg').default} alt="" />
                                    }

                                    <div className="billing">
                                        <h3>{t('cart.billing-payment')}</h3>

                                        {validationErrors && validationErrors.length > 0 && 
                                            <div className={Style.validationErrors}>
                                                {validationErrors.map((validationError) => (
                                                    <p>{validationError}</p>
                                                ))}
                                            </div>
                                        }

                                        <label>
                                            {t('cart.different-billing-address')}
                                            <input type="checkbox" name="differentBillingAddress" onChange={handleChange} checked={differentBillingAddress} />
                                        </label>

                                        <div className="billingAddress" style={differentBillingAddress ? {} : {display: 'none'}}>
                                            <label>
                                                {t('cart.name')}
                                                <input type="text" name="billing_first_name" placeholder={t('checkout.placeholder-first-name')} value={fields.billing_first_name} onChange={handleChange} />
                                                <input type="text" name="billing_last_name" placeholder={t('checkout.placeholder-last-name')} value={fields.billing_last_name} onChange={handleChange}/>
                                            </label>
                                            
                                            <label>
                                                {t('cart.address')}
                                                <input type="text" name="billing_address_1" placeholder={t('checkout.placeholder-address-1')} value={fields.billing_address_1} onChange={handleChange}/>
                                                <input type="text" name="billing_address_2" placeholder={t('checkout.placeholder-address-2')} value={fields.billing_address_2} onChange={handleChange}/>
                                                <input type="text" name="billing_city" placeholder={t('checkout.placeholder-town')} value={fields.billing_city} onChange={handleChange}/>
                                                <input type="text" name="billing_postcode" placeholder={t('checkout.placeholder-postcode')} value={fields.billing_postcode} onChange={handleChange}/>

                                                {availCountries && availCountries.length > 0 &&
                                                    <select value={fields.billing_country} name="billing_country" onChange={handleChange}>
                                                        {availCountries.map((availCountry) => (
                                                            <option key={availCountry.code} value={availCountry.code}>
                                                                {availCountry.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                }
                                            </label>
                                        </div>
                                    </div>
                            
                                    <div className="paymentMethods">
                                        <h3>{t('cart.select-payment')}</h3>
                                        <label>
                                            <div>
                                                {/* <CardElement /> */}
                                                <PaymentElement options={paymentElementOptions} />
                                            </div>
                                        </label>

                                        {paymentError && 
                                            <div className={Style.validationErrors}>{paymentError}</div>
                                        }
                                    </div>

                                    <div className={Style.controlsCont}>
                                        <a className={Theme.btnTextGrey} onClick={handleStepBackward}>Previous step</a>
                                        <input type="submit" value="Pay now" className={Theme.btnDarkBig} disabled={processing} />
                                    </div>
                                </div>
                            </div>
                        </>
                    </CSSTransition>
                    </>
                }

            </form>
        </div>
    );
}