import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Style from './LanguageSelect.module.css';
import { localeRedirect } from "../internationalisation/internationalisation";

export default function LanguageSelect() {

    const {localeCode} = useSelector(state => state.internationalisation);
    const dispatch = useDispatch();
    const localeTestMode = false;

    function handleLanguageChange(event) {

        // redirect to subdomain
        let locale = event.target.value;
 
        localeRedirect(locale)
    }


    return (
        <>
            {localeCode && (
                <select onChange={handleLanguageChange} value={localeCode} className={Style.languageSelect}>
                    <option key="en-UK" value="UK">United Kingdom</option>
                    <option key="en-US" value="US">United States</option>
                    <option key="en-AU" value="AU">Australia</option>
                    <option key="en-NZ" value="NZ">New Zealand</option>
                    <option key="nl-NL" value="NL">Nederland (Nederlands)</option>
                    {/* <option key="en-NL" value="EN-NL">Netherlands (English)</option> */}
                    <option key="es-ES" value="ES">España (Español)</option>
                    {/* <option key="en-ES" value="EN-ES">Spain (English)</option> */}
                    <option key="fr-FR" value="FR">France (Français)</option>
                    {/* <option key="en-FR" value="EN-FR">France (English)</option> */}
                    <option key="it-IT" value="IT">Italia (Italiana)</option>
                    {/* <option key="en-IT" value="EN-IT">Italy (English)</option> */}
                    <option key="pt-PT" value="PT">Portugal (Português)</option>
                    {/* <option key="en-PT" value="EN-PT">Portugal (English)</option> */}
                    <option key="de-DE" value="DE">Deutschland (Deutsch)</option>
                    {/* <option key="en-DE" value="EN-DE">Germany (English)</option> */}
                    <option key="en-IE" value="IE">Ireland</option>
                </select>
            )}
        </>
    );
}

