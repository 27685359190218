import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Content from "../../layouts/Content";
import Theme from "../../theme.module.css";
import Style from "./Header.module.css";
import DesktopMegaMenu from "../../features/menu/DesktopMegaMenu";
import HeaderTop from "./HeaderTop";
import SearchBar from "../../features/searchBar/SearchBar";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MobileMenu from "../../features/menu/MobileMenu";
import NoticeBanner from "./NoticeBanner";


function Header({mobileSearchBarActive, setMobileSearchBarActive}) {

    const [megaMenuActive, setMegaMenuActive] = useState('');
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const {t, i18n}  = useTranslation('common');
    const {isMobile} = useSelector(state => state.mobile);

    // Close mega menu when clicking a link
    useEffect(() => {

        function handleGlobalClick(event) {
            // Check if the clicked element is a Link
            if (event.target.tagName === "A" && event.target.closest("li")) {
                setMegaMenuActive(''); // Close mega menu
            }
        }

        document.addEventListener("click", handleGlobalClick);

        return () => {
            document.removeEventListener("click", handleGlobalClick);
        };
    }, []);

    return (
        <>
        {!isMobile ? (
            // desktop
            <header onMouseLeave={() => setMegaMenuActive('')}>
                <div className={Style.outerCont}>
                    <HeaderTop />
                    <div className={Style.headerMainOuter}>
                        <Content>
                            <div className={Theme.flexRow} style={{alignItems: "center", height: "100%", justifyContent: "flex-start", width: "100%"}}>
                                <Link to="/" className={Style.logo}>
                                    <img src={require('../../assets/img/trw_logo_black_stacked.png')} alt="logo"/>
                                </Link>
                                <nav>
                                    <ul className={Theme.flexRow}>
                                        <li onMouseOver={() => setMegaMenuActive('f1')} onClick={() => setMegaMenuActive('f1')}><a>{t('header.f1')}</a></li>
                                        <li onMouseOver={() => setMegaMenuActive('motorsport')} onClick={() => setMegaMenuActive('motorsport')}><a>{t('header.motorsport')}</a></li>
                                        <li onMouseOver={() => setMegaMenuActive('sport')} onClick={() => setMegaMenuActive('sport')}><a>{t('header.sport')}</a></li>
                                        <li onMouseOver={() => setMegaMenuActive('department')} onClick={() => setMegaMenuActive('items')}><a>{t('header.department')}</a></li>
                                    </ul>
                                </nav>
                                <div className={Style.iconCont}>
                                    <div className={Theme.flexRow}>
                                        <SearchBar mobileSearchBarActive={mobileSearchBarActive} setMobileSearchBarActive={setMobileSearchBarActive} />
                                        <Link to="/my-account">
                                            <img src={require('../../assets/img/icon_account.svg').default} className={Style.icons} alt="my-account" />
                                        </Link>
                                        <Link to="/cart">
                                            <img src={require('../../assets/img/icon_cart.svg').default} className={Style.icons} alt="cart" />
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        </Content>
                    </div>
                    <NoticeBanner />
                </div>
                <DesktopMegaMenu megaMenuActive={megaMenuActive} setMegaMenuActive={setMegaMenuActive} />
            </header>
        ) : (
            // mobile
            <div>
                <header className={`${Style.mobile}`}>
                    <div className={Style.outerCont}>
                        <HeaderTop />
                        <div className={Style.headerMainOuter}>
                            <Content>
                                <div className={`${Theme.flexRow} ${Style.headerMainInner}`}>
                                    <div className={Style.leftCont}>
                                        <img src={require('../../assets/img/burger.svg').default} className={Style.burger} onClick={() => {setMobileMenuActive(!mobileMenuActive)}} />

                                        <Link to="/" className={Style.logo}>
                                            <img src={require('../../assets/img/trw_logo_black_stacked.png')} alt="logo"/>
                                        </Link>
                                    </div>

                                    <div className={Style.iconCont}>
                                        <div className={Theme.flexRow}>
                                            <SearchBar mobileSearchBarActive={mobileSearchBarActive} setMobileSearchBarActive={setMobileSearchBarActive} />
                                            <Link to="/my-account">
                                                <img src={require('../../assets/img/icon_account.svg').default} className={Style.icons} alt="my-account" />
                                            </Link>
                                            <Link to="/cart">
                                                <img src={require('../../assets/img/icon_cart.svg').default} className={Style.icons} alt="cart" />
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </Content>
                        </div>
                        <NoticeBanner />
                    </div>
                    <MobileMenu mobileMenuActive={mobileMenuActive} setMobileMenuActive={setMobileMenuActive} />
                </header>
            </div>
        )}
        </>
    );
}

export default Header;