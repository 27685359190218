import React, { useEffect } from "react";  
import { useSelector, useDispatch } from "react-redux";
import cartSlice from "./cartSlice";
import CartItemSingle from "./CartSingleItem";
import { useTranslation } from "react-i18next";


export default function CartItems() {

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation('common');
    const {lineItems} = useSelector(state => state.cart);

    console.log(lineItems);

    return (
        <div className="cartItems">
            {lineItems && lineItems.length > 0 ? lineItems.map((lineItem, index) => (
                <CartItemSingle cartItem={lineItem} />
            )) :
                <div>
                    <p>{t('cart.empty')}</p>
                </div>
            }
        </div>
    );
}