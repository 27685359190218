import React from "react";
import { useSelector } from 'react-redux';
import "./ImageBox.css";

export default function ImageBox({text, image, link}) {

    const {isMobile} = useSelector(state => state.mobile);

    function handleLinkClick(event) {
        if (!link) {
            event.preventDefault();
        }
    }

    return (
        <>
            <div className={`${isMobile ? 'mobile' : ''} imageBoxOuter`}>
                <a className="imageBox" href={link || ''} onClick={handleLinkClick}>
                    <div className="imageBoxInner" style={{backgroundImage: 'url(' + image + ')'}}>
                        {text &&
                            <h4>{text}</h4>
                        }
                    </div>
                </a>
            </div>
        </>
    );
}