import React, { useState, useEffect, useRef } from 'react';
import client from '../../services/woocom';
import { useSelector } from 'react-redux';
import SingleCatalogProduct from '../productCatalog/SingleCatalogProduct';
import { Swiper, SwiperSlide } from 'swiper/react';
import './ProductSlider.css';


export default function ProductSlider({filters, classNames, numSlides}) {

    const {isMobile} = useSelector(state => state.mobile);
    const [sliderProducts, setSliderProducts] = useState([]);
    const swiperRef = useRef(null);

    classNames += ' product-swiper';

    // get products
    useEffect(() => {

        let params = {
            limit: 9,
            page: 1,
            filter: filters
        }

        async function getSliderProducts() {
            let response = await client.get('/wc-products', {params});
            setSliderProducts(response.data.products);
        }

        getSliderProducts();

    }, []);

    useEffect(() => {
        console.log('slider products changed');
        console.log(sliderProducts);
    }, [sliderProducts]);
    
    const handlePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.swiper.slidePrev();
        }
    }

    const handleNext = () => {
        console.log('next');
        if (swiperRef.current) {
            swiperRef.current.swiper.slideNext();
        }
    }


    return (
            <div className={`${isMobile && 'mobile'} productSlider`}>
            {Array.isArray(sliderProducts) && sliderProducts.length > 0 ? (
                <div>
                    <Swiper
                        spaceBetween={isMobile ? 5 : 15}
                        slidesPerView={numSlides}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        ref={swiperRef}
                        className={classNames}
                    >
                        {sliderProducts.map((product, index) => (
                            <SwiperSlide key={index}>
                                <SingleCatalogProduct key={index} product={product} />
                            </SwiperSlide>
                        ))}
                        <a className="header-prev" onClick={handlePrev}><img src={require('../../assets/img/arrow_left.svg').default} alt="left-arrow" /></a>
                        <a className="header-next" onClick={handleNext}><img src={require('../../assets/img/arrow_right.svg').default} alt="right-arrow" /></a>
                    </Swiper>
                </div>
            ):(
                
                <p>no matching products found</p>
            )}
        </div>
    ); 
}