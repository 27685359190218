
/**
 * Facebook Tracking Service
 * 
 * @class FacebookTracking
 * @author Gnar Software
 * @version 1.0.0
 */
class FacebookTracking {

    constructor() {
        // get all facebook pixel codes from .env
        this.pixels = { 
            UK: process.env.REACT_APP_FACEBOOK_PIXEL_ID_UK
        }
        
        this.fbqIife = !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');

        this.initiated = false;
    }


    /**
     * Load Facebook Pixel SDK if not already loaded
     * 
     * @param {string} localeCode
     */
    loadFacebookPixelScript = (localeCode) => {
        if (!this.initiated) {
            if (this.pixels[localeCode]) {
                window.fbq('init', this.pixels[localeCode]);
                console.log('FB pixel loaded', this.pixels[localeCode]);
                this.initiated = true;
            } else {
                return false;
            }
        }

        return true;
    }


    /**
     * Page View
     * 
     * @param {string} localeCode
     */
    pageView = (localeCode) => {
        if (this.loadFacebookPixelScript(localeCode)) {
            let eventID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);            
            window.fbq('track', 'PageView', {}, {eventID: eventID});
            console.log('FB pixel page view', eventID);
        }
    }


    /**
     * Content View
     * 
     * @param {string} localeCode
     * @param {object} contentData 
     */
    contentView = (localeCode, contentData) => {
        if (this.loadFacebookPixelScript(localeCode)) {
            window.fbq('track', 'ViewContent', {content_name: "Tickets Page"}, {eventID: contentData.eventID});
            console.log('FB pixel content view', contentData.eventID);
        }
    }   


    /**
     * Add to cart
     * 
     * @param {string} localeCode
     * @param {object} cartData 
     */
    addToCart = (localeCode, cartData) => {
        if (this.loadFacebookPixelScript(localeCode)) {
            window.fbq('track', 'AddToCart', {}, {eventID: cartData.eventID});
            console.log('FB pixel add to cart', cartData.eventID);
        }
    }


    /**
     * Purchase
     * 
     * @param {string} localeCode
     * @param {object} purchaseData 
     * @param {string} eventID
     */
    purchase = (localeCode, purchaseData, eventID) => {
        if (this.loadFacebookPixelScript(localeCode)) {

            const purchaseTotal = Math.round(purchaseData.total * 100) / 100;
            const currency = purchaseData.currency;

            window.fbq('track', 'Purchase', {currency: currency, value: purchaseTotal}, {eventID: eventID});
            console.log('FB pixel purchase', {currency: currency, value: purchaseTotal, eventID: eventID});
        }        
    }
}

const FBTracking = new FacebookTracking();
export default FBTracking;