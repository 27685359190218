export const teamsDrivers = [
    {
        key: "lewis_hamilton",
        name: "Lewis Hamilton",
        team: "mercedes",
        description: "Lewis is the greatest driver of all time. Blah blah blah."
    },
    {
        key: "fernando_alonso",
        name: "Fernando Alonso",
        team: "aston_martin",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "max_verstappen",
        name: "Max Verstappen",
        team: "red_bull",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "charles_leclerc",
        name: "Charles Leclerc",
        team: "ferrari",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "lando_norris",
        name: "Lando Norris",
        team: "mclaren",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "oscar_piastri",
        name: "Oscar Piastri",
        team: "mclaren",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "george_russell",
        name: "George Russell",
        team: "mercedes",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "sergio_perez",
        name: "Sergio Perez",
        team: "red_bull",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "carlos_sainz",
        name: "Carlos Sainz",
        team: "ferrari",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "lance_stroll",
        name: "Lance Stroll",
        team: "Aston Martin",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "esteban_ocon",
        name: "Esteban Ocon",
        team: "alpine",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "pierre_gasly",
        name: "Pierre Gasly",
        team: "alpine",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "alex_albon",
        name: "Alex Albon",
        team: "williams",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "nico_hulkenberg",
        name: "Nico Hulkenberg",
        team: "haas",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "valtteri_bottas",
        name: "Valtteri Bottas",
        team: "kick_sauber",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "zhou_guanyu",
        name: "Zhou Guanyu",
        team: "kick_sauber",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "yuki_tsunoda",
        name: "Yuki Tsunoda",
        team: "visa_cashapp_rb",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "kevin_magnussen",
        name: "Kevin Magnussen",
        team: "haas",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "logan_sargeant",
        name: "Logan Sargeant",
        team: "williams",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "nyck_de_vries",
        name: "Nyck de Vries",
        team: "visa_cashapp_rb",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "daniel_ricciardo",
        name: "Daniel Ricciardo",
        team: "visa_cashapp_rb",
        description: "Fernando is a great driver. Blah blah blah."
    },
    {
        key: "formula_one",
        name: "Formula 1",
        drivers: []
    },
    {
        key: "red_bull",
        name: "Red Bull Racing",
        drivers: ["max_verstappen", "sergio_perez"]
    },
    {
        key: "ferrari",
        name: "Ferrari",
        drivers: ["charles_leclerc", "carlos_sainz"]
    },
    {
        key: "mclaren",
        name: "Mclaren",
        drivers: ["lando_norris", "oscar_piastri"]
    },
    {
        key: "mercedes",
        name: "Mercedes",
        drivers: ["lewis_hamilton", "george_russell"]
    },
    {
        key: "aston_martin",
        name: "Aston Martin Cognizant",
        drivers: ["fernando_alonso", "lance_stroll"]
    },
    {
        key: "williams",
        name: "Williams",
        drivers: ["alex_albon", "logan_sargeant"]
    },
    {
        key: "visa_cashapp_rb",
        name: "Visa Cash App RB",
        drivers: ["yuki_tsunoda", "nyck_de_vries"]
    },
    {
        key: "alpine",
        name: "Alpine",
        drivers: ["esteban_ocon", "pierre_gasly"]
    },
    {
        key: "haas",
        name: "Haas",
        drivers: ["nico_hulkenberg", "kevin_magnussen"]
    },
    {
        key: "kick_sauber",
        name: "Kick Sauber",
        drivers: ["valtteri_bottas", "zhou_guanyu"]
    }
];